import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				 Usługi sprzątania ShineBright
			</title>
			<meta name={"description"} content={"Spraw, by Twoja przestrzeń lśniła bez wysiłku"} />
			<meta property={"og:title"} content={" Usługi sprzątania ShineBright"} />
			<meta property={"og:description"} content={"Spraw, by Twoja przestrzeń lśniła bez wysiłku"} />
			<meta property={"og:image"} content={"https://fleximflex.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fleximflex.com/img/325452345.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fleximflex.com/img/325452345.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fleximflex.com/img/325452345.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fleximflex.com/img/325452345.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fleximflex.com/img/325452345.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fleximflex.com/img/325452345.png"} />
			<meta name={"msapplication-TileColor"} content={"https://fleximflex.com/img/325452345.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Nasza droga do blasku
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				Firma ShineBright Cleaning Services rozpoczęła swoją działalność z prostą misją: sprawić, by domy lśniły od wewnątrz. To, co zaczęło się jako mały zespół z wielkimi marzeniami, rozwinęło się w wiodącą usługę sprzątania, znaną z naszego zaangażowania w jakość, szczegóły i środowisko. Nasza podróż jest oparta na przekonaniu, że każdy dom zasługuje na to, aby czuć się wyjątkowo, a każde sprzątanie jest okazją, aby uczynić czyjś dzień jaśniejszym.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://fleximflex.com/img/3.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://fleximflex.com/img/4.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="flex-start"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						font="--base"
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--light"
						letter-spacing="2px"
					>
						Podnieś swój dom, podnieś swoje życie z ShineBright
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Różnica ShineBright
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
				W ShineBright nie zajmujemy się tylko sprzątaniem - tworzymy przestrzenie, które inspirują szczęście i spokój. Nasz zespół jest sercem wszystkiego, co robimy, składa się z pełnych pasji, wykwalifikowanych profesjonalistów, którzy podzielają naszą wizję doskonałości w sprzątaniu domów. Inwestujemy w ciągłe szkolenia i ekologiczne rozwiązania, ponieważ dbamy o nasz zespół, naszych klientów i naszą planetę.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Stwórz historię swojego domu z ShineBright
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Twój dom jest odzwierciedleniem Twojej historii, a my jesteśmy tutaj, aby pomóc mu zabłysnąć. Wybór ShineBright oznacza nie tylko wynajęcie usługi sprzątania, ale także stworzenie środowiska, w którym mogą rozwijać się najlepsze chwile Twojego życia. Pozwól nam zająć się szczegółami, abyś mógł skupić się na życiu, miłości i tworzeniu wspomnień w przestrzeni, która jest wyjątkowo Twoja.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Łączność
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});